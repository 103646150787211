// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAaTl_QVSS7gQYju5VxTnoSkHs671minBw",
  authDomain: "practiceml-32100.firebaseapp.com",
  projectId: "practiceml-32100",
  storageBucket: "practiceml-32100.appspot.com",
  messagingSenderId: "179752360751",
  appId: "1:179752360751:web:f7e1302445fc35520d9fda",
  measurementId: "G-0VXD1WDC2Z",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const fireStoreDB = getFirestore(firebaseApp);

export { fireStoreDB };
