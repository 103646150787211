import React from "react";
import "./InfoCard.css";

const InfoCard = ({ imageSrc, title, description }) => (
  <div className="info-section grid-item">
    <div className="image-container">
      <img src={imageSrc} alt="" className="round-image" />
    </div>
    <div className="text-container">
      <h4 className="heading">{title}</h4>
      <p className="description">{description}</p>
    </div>
  </div>
);

export default InfoCard;
