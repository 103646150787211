// src/components/IntroSection.js
import React, { useState } from "react";
import { fireStoreDB } from "../../../firebase";
import { TextField, Button, Container, Typography } from "@mui/material";
import { addDoc, collection } from "firebase/firestore";
import logo from "../../../assets/images/logo-test.png"; // assuming the path to your logo image
import { Link } from "react-router-dom";
import mixpanel from "../../../mixpanelConfig";

import "./IntroSection.css";

const IntroSection = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    mixpanel.track("Home Page Early Access", {
      email: email,
    });
    await addDoc(collection(fireStoreDB, "earlyAccess"), { email });
    setSubmitted(true);
  };

  return (
    <Container className="height-min-100 flex-container intro-section">
      <div className="header">
        <div className="logo-container">
          <img src={logo} alt="Company Logo" className="logo" />
        </div>
      </div>
      <div className="content-container">
        <Typography
          variant="h4"
          gutterBottom
          className="text-styling text-title"
        >
          AI Advanced Interview Preparation
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="text-styling two-lines"
        >
          Embark on a personalized journey of AI tailored interview preparation
          to perfect your interview and achieve your dream career goals!
        </Typography>
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/tNBVfS8e8mo"
          title="YouTube video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ marginBottom: "20px", maxWidth: "560px" }}
        ></iframe>
        <div>
          <Link
            to="/demo"
            onClick={() => {
              mixpanel.track("Home Page Demo Interview", {});
            }}
          >
            <button className="demo-button">Interviewer Demo</button>
          </Link>
        </div>
        {!submitted ? (
          <form
            onSubmit={handleSubmit}
            className="email-form" // Updated style className
          >
            <TextField
              variant="outlined"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
              className="email-input" // Updated style className
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="submit-button" // Updated style className
            >
              Join Early Access!
            </Button>
          </form>
        ) : (
          <Typography variant="subtitle1" className="text-styling two-lines">
            Thank you for your interest. We will reach out to you with early
            access details shortly.
          </Typography>
        )}
      </div>
    </Container>
  );
};

export default IntroSection;
