import React from "react";
import "./FeatureCard.css";

const FeatureCard = ({
  imageSrc,
  secondImageSrc,
  title,
  description,
  imagesOnRight = false,
}) => {
  return (
    <div className={`feature-card ${imagesOnRight ? "images-right" : ""}`}>
      <div className="feature-images">
        <div className="image-container-feature">
          <img src={imageSrc} alt={`${title}  1`} className="primary-image" />
          {secondImageSrc && (
            <img
              src={secondImageSrc}
              alt={`${title} -  2`}
              className="secondary-image"
            />
          )}
        </div>
      </div>
      <div className="feature-info">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};
export default FeatureCard;
