import React, { useState, useRef, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./MainInterview.css";
import aiInterviewer from "../../../../assets/images/ai-interviewer.png";
// import useSpeechToText from "react-hook-speech-to-text";
import { v4 as uuidv4 } from "uuid"; // Import uuid
import { useWhisper } from "@chengsokdara/use-whisper"; // Import useWhisper
import noCameraPlaceholder from "../../../../assets/images/no-connected-camera.png";
import mixpanel from "../../../../mixpanelConfig";

const seedText =
  "Hi! Today we will discuss some questions to understand your knowledge of machine learning fundamentals. Are you ready to start the interview?";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const addLeadingZero = (num) => (num < 10 ? `0${num}` : num);

const MainInterview = ({ startDate = new Date(), setFeedbackData }) => {
  const [loading, setLoading] = useState(false);
  const videoRef = useRef(null);
  const [processingEndInterview, setProcessingEndInterview] = useState(false);
  const [isInterviewExisting, setIsInterviewExisting] = useState(false);

  const navigate = useNavigate();
  const [micPermission, setMicPermission] = useState(null);
  // State to track if audio is currently being played
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const isFirstMount = useRef(true);

  const [initialAudioPlayed, setInitialAudioPlayed] = useState(false);

  const [sessionID, setSessionID] = useState(null); // State for sessionID
  const [isCameraActive, setIsCameraActive] = useState(false);

  const checkVideoPermissions = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: "camera",
      });

      if (permissionStatus.state === "granted") {
        // Permission has already been granted
        setIsCameraActive(true);
      } else {
        // Permission is not granted (denied or prompt)
        setIsCameraActive(false);
      }
    } catch (error) {
      console.error("Error checking camera permissions:", error);
      setIsCameraActive(false);
    }
  };

  useEffect(() => {
    // Generate the UUID on mount and play initial case
    if (isFirstMount.current) {
      const currentSessionID = uuidv4();
      setSessionID(currentSessionID);
      getAudioFromText(seedText, false, null, true);
      isFirstMount.current = false;
      checkVideoPermissions();
    } // Pass a flag to indicate it's the initial audio
  }, []);

  const checkMicPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // If we reach this point, permission was granted
      setMicPermission(true);
      // Stop the audio tracks to clean up
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      // If we reach this point, permission was denied or there was another error
      setMicPermission(false);
    }
  };

  const query = useQuery();
  const name = query.get("name") || "User";

  useEffect(() => {
    checkMicPermission();
  }, []);

  // Add a ref for the media stream
  const mediaStreamRef = useRef(null);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        setIsCameraActive(true); // Camera is active
        mediaStreamRef.current = stream; // Store the stream in the ref
      })
      .catch((err) => {
        console.error("Error accessing camera:", err);
        setIsCameraActive(false);
      });

    // Cleanup function to stop the camera stream
    return () => {
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, []);

  const {
    recording,
    speaking,
    transcribing,
    transcript,
    startRecording,
    stopRecording,
  } = useWhisper({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, // Ensure this is set in your .env file
  });

  const sendToExternalServer = useCallback(
    async (transcript) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_BACKEND}/interview/get_response/?message=${transcript}&session_id=${sessionID}&remaining_time=${countdownRef.current}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setIsInterviewExisting(true);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        return await response.json();
      } catch (error) {
        console.error("Error sending transcript to external server:", error);
        return null;
      }
    },
    [sessionID]
  );
  const [countdown, setCountdown] = useState(900);
  const countdownRef = useRef(countdown);

  useEffect(() => {
    countdownRef.current = countdown; // Update the ref to the current value
  }, [countdown]);

  const handleEndInterview = useCallback(async () => {
    setProcessingEndInterview(true); // start the processing
    localStorage.setItem("sessionID", sessionID);
    try {
      const feedbackResponse = await fetch(
        `${process.env.REACT_APP_BASE_BACKEND}/interview/get_feedback_info/?session_id=${sessionID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!feedbackResponse.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await feedbackResponse.json();
      setFeedbackData(data);
      mixpanel.track("Interview Ended", {
        name: name,
      });
      navigate(`/demo/feedback?name=${name}`, { replace: true });
    } catch (error) {
      console.error("Error fetching feedback data:", error);
    } finally {
      setProcessingEndInterview(false); // end the processing
    }
  }, [sessionID, navigate, name, setFeedbackData]);

  useEffect(() => {
    if (!recording && !transcribing && transcript?.["text"]?.length > 0) {
      // User has stopped recording, and we have results to process
      const processResults = async () => {
        // results.forEach((result) => {
        //   finalResult += result["transcript"] + ". ";
        // });
        const result = await sendToExternalServer(transcript?.["text"]);
        if (result) {
          getAudioFromText(
            result["response"],
            result["end_session"],
            handleEndInterview
          );
        }
      };
      processResults();
    }
  }, [
    recording,
    handleEndInterview,
    sendToExternalServer,
    transcript,
    transcribing,
  ]);

  const formattedDate = `${startDate.toLocaleString("default", {
    month: "short",
  })} ${addLeadingZero(
    startDate.getDate()
  )}, ${startDate.getFullYear()} | ${addLeadingZero(
    startDate.getHours()
  )}:${addLeadingZero(startDate.getMinutes())}`;

  const handleStartRecording = () => {
    if (initialAudioPlayed && !isAudioPlaying) {
      resumeAudioContext();
      startRecording();
    }
  };

  const handleStopRecording = async () => {
    stopRecording();
    setLoading(true); // Set loading here
  };
  let audioContext = new (window.AudioContext || window.webkitAudioContext)();

  const resumeAudioContext = () => {
    if (audioContext && audioContext.state === "suspended") {
      audioContext.resume();
    }
  };
  useEffect(() => {
    resumeAudioContext();
    return () => {
      if (audioContext.state !== "closed") {
        audioContext.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAudioFromText = async (
    text,
    endSession,
    handleEndInterview,
    isInitialAudio
  ) => {
    try {
      const response = await fetch("https://api.openai.com/v1/audio/speech", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          model: "tts-1",
          voice: "onyx",
          input: text,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch the audio");
      }

      const audioData = await response.blob();
      if (!audioData.size) {
        throw new Error("Empty audio blob!");
      }

      // Create an AudioContext (should be reused by multiple plays)
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();

      // Convert the blob to an ArrayBuffer
      const arrayBuffer = await audioData.arrayBuffer();

      // Decode the audio data asynchronously
      audioContext.decodeAudioData(
        arrayBuffer,
        async (decodedData) => {
          // Create a buffer source
          const source = audioContext.createBufferSource();
          source.buffer = decodedData;

          // Connect the source to the context's destination (the speakers)
          source.connect(audioContext.destination);

          // Log and handle the 'play' event
          setLoading(false);
          setIsAudioPlaying(true);

          // Play the audio
          source.start(0);

          // Handle the audio playback ending
          source.onerror = (ev) => {
            console.log(ev);
          };
          source.onended = () => {
            setIsAudioPlaying(false);
            if (isInitialAudio) {
              setInitialAudioPlayed(true);
            }
            // Close the audio context to release resources
            audioContext.close();
            if (endSession) {
              handleEndInterview();
            }
          };
        },
        (e) => {
          console.log("Error decoding audio data", e);
          // Handle the audio error
          setIsAudioPlaying(false);
        }
      );
    } catch (error) {
      console.error("Error in TTS:", error);
      // Handle the error state
      setIsAudioPlaying(false);
    }
  };

  useEffect(() => {
    // Function to format the countdown time
    // Update the countdown every second
    const timer = setInterval(() => {
      setCountdown((prevTime) => {
        if (prevTime <= 1) clearInterval(timer); // Clear interval when countdown reaches zero
        return prevTime - 1;
      });
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, []);

  // const getAudioFromText = (
  //   text,
  //   endSession,
  //   handleEndInterview,
  //   isInitialAudio
  // ) => {
  //   console.log(text, isInitialAudio);
  //   const msg = new SpeechSynthesisUtterance();
  //   // Update the state when audio starts playing
  //   msg.onstart = () => {
  //     console.log("Here");
  //     setLoading(false);

  //     setIsAudioPlaying(true);
  //   };

  //   // Update the state when audio finishes
  //   msg.onend = () => {
  //     console.log(text, isInitialAudio);

  //     if (isInitialAudio) {
  //       setInitialAudioPlayed(true);
  //     }
  //     setIsAudioPlaying(false);
  //     if (endSession) {
  //       handleEndInterview();
  //     }
  //   };

  //   msg.onerror = (ev) => {
  //     console.log(ev);
  //   };

  //   // Set the text message
  //   msg.text = text;

  //   // Optionally adjust the rate (speed) and pitch
  //   msg.rate = 0.8; // slower speech
  //   msg.pitch = 0.8; // lower pitch for a deeper voice

  //   // Get list of available voices
  //   const voices = window.speechSynthesis.getVoices();

  //   // Filter voices to get male voices, you can adjust this further based on what's available
  //   const maleVoices = voices.filter((voice) => voice.gender === "male");

  //   // If there are male voices available, choose the first one
  //   if (maleVoices.length > 0) {
  //     msg.voice = maleVoices[0];
  //   }

  //   // Speak the message
  //   window.speechSynthesis.speak(msg);
  // };
  const formattedCountdown = `${addLeadingZero(
    Math.floor(countdown / 60)
  )}:${addLeadingZero(countdown % 60)}`;

  return (
    <div className="interview-container">
      <div className="header">Interview in Progress</div>
      <div className="date-time">{formattedDate}</div>
      <div className="countdown-timer">
        <strong>Time Remaining:</strong> {formattedCountdown}
      </div>
      <div className="interviewees">
        <div className={`user ${speaking ? "speaking-highlight" : ""}`}>
          <div className="label">{name}</div>
          {isCameraActive ? (
            <video ref={videoRef} autoPlay muted></video>
          ) : (
            <img
              className="camera-connected"
              src={noCameraPlaceholder}
              alt="No Camera Available"
            />
          )}
        </div>
        <div className={`ai ${isAudioPlaying ? "speaking-highlight" : ""}`}>
          <div className="label ai-label">AI Interviewer</div>
          <img src={aiInterviewer} alt="AI Interviewer" className="ai-img" />
        </div>
      </div>
      <div className="controls">
        <div className="status">
          {processingEndInterview ? null : loading ? (
            <button className="loading-button" disabled={true}>
              <span className="recording-icon">🔄</span> Loading...
            </button>
          ) : isAudioPlaying ? (
            <button disabled={true} className="speaking-button">
              <span className="recording-icon">🗣️</span> Speaking...
            </button>
          ) : recording ? (
            <button
              onClick={handleStopRecording}
              disabled={
                micPermission === false ||
                isAudioPlaying ||
                transcribing ||
                processingEndInterview
              }
            >
              <span className="recording-icon">■</span> Stop Recording
            </button>
          ) : (
            <button
              onClick={handleStartRecording}
              disabled={
                !initialAudioPlayed || isAudioPlaying || processingEndInterview
              }
            >
              <span className="recording-icon">⚫</span> Start Recording
            </button>
          )}
          {processingEndInterview ? (
            <button className="loading-button" disabled={true}>
              <span className="recording-icon">🔄</span> Evaluating...
            </button>
          ) : (
            <button
              className={`end-interview ${
                !isInterviewExisting ? "disabled-end" : ""
              }`}
              onClick={handleEndInterview}
              disabled={recording || isAudioPlaying || !isInterviewExisting}
            >
              End Interview
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainInterview;
