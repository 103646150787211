import React, { useState } from "react";
import { fireStoreDB } from "../../../../firebase";
import { TextField, Button, Typography } from "@mui/material";
import { addDoc, collection } from "firebase/firestore";
import { Link, useLocation } from "react-router-dom";
import mixpanel from "../../../../mixpanelConfig";

import "./Feedback.css";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SignupForm = ({ isLoading, setIsLoading }) => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e, name) => {
    setIsLoading(true);
    e.preventDefault();
    const sessionID = localStorage.getItem("sessionID");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_BACKEND}/interview/get_feedback/?session_id=${sessionID}&candidate_email=${email}&candidate_name=${name}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error fetching feedback data:", error);
    } finally {
      // await addDoc(collection(fireStoreDB, "earlyAccess"), { email });
      try {
        mixpanel.track("Received Feedback", {
          name: name,
          sessionID: sessionID,
          email: email,
        });
        await addDoc(collection(fireStoreDB, "feedback"), { email, sessionID });
      } catch (error) {
        console.error("Error with firebase feedback data:", error);
      }
      setIsLoading(false);
      setSubmitted(true);
    }
  };

  const query = useQuery();
  const name = query.get("name") || "Candidate";

  return !submitted ? (
    <form onSubmit={(e) => handleSubmit(e, name)} className="email-form">
      <TextField
        variant="outlined"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email"
        required
        className="email-input"
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className="submit-button"
        disabled={email.length === 0 || isLoading}
      >
        {!isLoading ? "Get Feedback!" : "Loading..."}
      </Button>
    </form>
  ) : (
    <Typography variant="subtitle1" className="text-styling two-lines">
      Thank you for your interest. We will send you an email with detailed
      feedback and early access details soon!
    </Typography>
  );
};

// const FeedbackTabContent = ({ title, data }) => (
//   <div className="feedback-tab-content">
//     <h2 style={{ textTransform: "uppercase" }}>{title}</h2>
//     <ScoreDisplay score={data.score} />
//     <p>
//       <strong>Strengths:</strong> {data.strengths}
//     </p>
//     <p>
//       <strong>Areas to Improve:</strong> {data.areas_to_improve}
//     </p>
//   </div>
// );

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div role="tabpanel" hidden={value !== index} {...other}>
//       {value === index && <Box p={3}>{children}</Box>}
//     </div>
//   );
// }

// const ScoreDisplay = ({ score }) => {
//   const percentage = score / 5;

//   // Shades: Soft Red: rgb(242, 85, 96) and Soft Green: rgb(106, 204, 153)
//   const r = Math.floor(242 - 136 * percentage);
//   const g = Math.floor(85 + 119 * percentage);
//   const b = Math.floor(96 + 57 * percentage);

//   const bgColor = `rgb(${r}, ${g}, ${b})`;

//   return (
//     <div className="score-display" style={{ backgroundColor: bgColor }}>
//       {score}
//     </div>
//   );
// };

const Feedback = ({ feedbackData }) => {
  const [isLoading, setIsLoading] = useState(false);
  // const [tabValue, setTabValue] = useState(0); // Default tab is the first tab
  // const feedbackKeys = Object.keys(feedbackData).filter(
  //   (key) => key !== "error"
  // );

  // const handleChange = (event, newValue) => {
  //   setTabValue(newValue);
  // };

  // const totalScore =
  //   Object.values(feedbackData)
  //     .filter((value) => typeof value !== "boolean")
  //     .reduce((acc, curr) => acc + curr.score, 0) / 3;

  return (
    <div className="feedback-component">
      <div className="feedback-container">
        <h1>Interview Feedback</h1>
        {feedbackData.error ? (
          <Typography variant="h5" style={{ color: "red" }}>
            Something went wrong.
          </Typography>
        ) : (
          <>
            <h4 className="cong-header">
              Congratulations on completing your interview! 🎉
            </h4>
            <p className="you-have-header">You have:</p>
            <ul className="teaser-list">
              <li>{feedbackData["strengths_count"]} strengths</li>
              <li>
                {feedbackData["improvements_count"]} areas for improvement
              </li>
            </ul>
            <p className="signup-text">
              Sign up to get your detailed feedback over email!
            </p>
            {/* <Tabs
              value={tabValue}
              onChange={handleChange}
              centered // Center the tabs horizontally
              indicatorColor="primary"
              textColor="primary"
              aria-label="feedback tabs"
            >
              {feedbackKeys.map((key, index) => (
                <Tab label={key.replace("_", " ")} key={key} />
              ))}
            </Tabs>
            {feedbackKeys.map((key, index) => (
              <TabPanel value={tabValue} index={index} key={key}>
                <FeedbackTabContent
                  title={key.replace("_", " ")}
                  data={feedbackData[key]}
                />
              </TabPanel>
            ))} */}
            <SignupForm isLoading={isLoading} setIsLoading={setIsLoading} />
          </>
        )}
        <Link to="/">
          <Button
            variant="outlined"
            style={{ marginTop: "20px" }}
            onClick={() => {
              localStorage.removeItem("sessionID");
            }}
            disabled={isLoading}
          >
            Start over
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Feedback;
