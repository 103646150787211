import React, { useState, useEffect } from "react";
import MainInterview from "./demoSteps/MainInterview";
import { Route, Routes, useNavigate } from "react-router-dom";
import StartInterview from "./demoSteps/StartInterview";
import Feedback from "./demoSteps/Feedback";

const feedbackTestData = {
  ml_knowledge: {
    strengths:
      "Strong understanding of various ML algorithms and their applications.",
    areas_to_improve:
      "Focus more on the practical implementation aspects and optimization.",
    score: 4,
  },
  communication: {
    strengths: "Clear articulation and good presentation skills.",
    areas_to_improve:
      "Engage more actively in discussions to gain diverse perspectives.",
    score: 3,
  },
  problem_solving: {
    strengths: "Adept at breaking down complex problems into manageable tasks.",
    areas_to_improve:
      "Try to explore multiple solutions before settling on one.",
    score: 5,
  },
};

const DemoContainer = () => {
  const [feedbackData, setFeedbackData] = useState(feedbackTestData);
  const [isLoading, setIsLoading] = useState(true); // Introduce the isLoading state
  const navigate = useNavigate();

  const fetchFeedbackData = async () => {
    const sessionID = localStorage.getItem("sessionID");

    if (sessionID) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_BACKEND}/interview/get_feedback_info/?session_id=${sessionID}`
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setFeedbackData(data);
        navigate("/demo/feedback", { replace: true });
      } catch (error) {
        console.error("Error fetching feedback data:", error);
      }
    }

    setIsLoading(false); // Set isLoading to false when the fetch is completed (either successfully or with error)
  };

  useEffect(() => {
    fetchFeedbackData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMobile = window.innerWidth <= 768;

  if (isMobile) {
    return (
      <div className="mobile-warning">
        Please try this on desktop as we only support desktop for now!
      </div>
    );
  }

  if (isLoading) {
    return <div>Loading...</div>; // Display a loading message while the fetch is ongoing
  }

  return (
    <div className="demo-container">
      <Routes>
        <Route
          path="/interview"
          element={
            <MainInterview setFeedbackData={(data) => setFeedbackData(data)} />
          }
        />
        <Route
          path="/feedback"
          element={<Feedback feedbackData={feedbackData} />}
        />
        <Route exact path="/" element={<StartInterview />} />
      </Routes>
    </div>
  );
};

export default DemoContainer;
