import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./StartInterview.css";
import startInterviewImg from "../../../../assets/images/start-interview.png";
import mixpanel from "../../../../mixpanelConfig";

const StartInterview = () => {
  // const [position, setPosition] = useState("");
  // const [company, setCompany] = useState("");
  // const [jobDescription, setJobDescription] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const goToInterview = (name) => {
    // Here you can pass data or perform other actions before navigating.
    mixpanel.track("Interview Started", {
      name: name,
    });

    navigate(`/demo/interview?name=${name}`); // Assuming you're using React Router.
  };

  return (
    <div className="start-interview-page">
      <h2 className="hi-header">Hi there!</h2>
      <h3 className="welcome-header">Welcome to your Practice ML Interview</h3>
      <img
        className="start-interview-img"
        src={startInterviewImg}
        alt="start-interview"
      />

      <section className="instructions-section">
        <h3 className="instructions-heading">
          A few instructions before we begin
        </h3>
        <ol className="instructions-list">
          <li>
            To begin the mock interview, enter your name below and click “Start
            Interview”.
          </li>
          <li>This interview comprises both audio and video.</li>
          <li>
            To record your response, click “Start Recording” and start speaking.
            Once you’re done, click "Stop Recording".
          </li>
          <li>
            Interact with the AI as you would with a real human. Feel free to
            ask clarifying questions whenever necessary.
          </li>
          <li>When you finish the interview, click "End Interview."</li>
          <li>Interview duration will be 15 minutes.</li>
        </ol>

        <div className="flex-container">
          <label className="input-label">
            <strong>Name:</strong>
          </label>
          <input
            className="text-input"
            type="text"
            value={name}
            onChange={(e) => {
              e.preventDefault();
              setName(e.target.value);
            }}
            placeholder=""
          />
        </div>
      </section>

      <footer className="start-footer">
        <button
          className="start-interview-button"
          onClick={() => {
            goToInterview(name);
          }}
          disabled={name.length === 0}
        >
          Start Interview
        </button>
      </footer>
    </div>
  );
};

export default StartInterview;
