import React, { useEffect } from "react";
import { Parallax } from "react-parallax";
import InfoCard from "./react-app/components/home-page/InfoCard";
import IntroSection from "./react-app/components/home-page/IntroSection";
import infoImage1 from "./assets/images/our-purpose.jpeg";
import infoImage2 from "./assets/images/our-team.jpeg";
import infoImage3 from "./assets/images/our-idea.jpeg";
import featureImage1 from "./assets/images/proposedReading.png";
import featureImage2 from "./assets/images/Collaborative feedback.png";
import featureImage3 from "./assets/images/NewInterview.png";
import featureImage4 from "./assets/images/fangCompanies.png";
import Footer from "./react-app/components/home-page/Footer";
import FeatureCard from "./react-app/components/home-page/FeatureCard";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import "./assets/css/home-page.css";
import DemoContainer from "./react-app/components/demo/DemoContainer";

const features = [
  {
    imageSrc: featureImage1,
    secondImageSrc: featureImage2,
    title: "Focused Feedback and Suggested Readings",
    description:
      "Receive nuanced feedback tailored to your interview performance, along with insightful reading recommendations.",
  },
  {
    imageSrc: featureImage3,
    title: "Customized Interview Preparation",
    imagesOnRight: true,
    description:
      "Provide your job target, preferences, and resume; each interview will be customized to align with your professional aspirations.",
  },
  {
    imageSrc: featureImage4,
    title: "Benchmarked Evaluation",
    description:
      "Benefit from our industry-standard evaluation heuristic, developed in consultation with experts from top-tier tech firms.",
  },
];

const sections = [
  {
    imageSrc: infoImage1,
    title: "Our Vision",
    description:
      "PracticeML aspires to be the premier provider of AI-driven mock interviews, aiding individuals in securing their dream jobs.",
  },
  {
    imageSrc: infoImage2,
    title: "Our Expertise",
    description:
      "With a team skilled in artificial intelligence and behavioral sciences, we’re dedicated to offer high quality interview practice experiences.",
  },
  {
    imageSrc: infoImage3,
    title: "Our Approach",
    description:
      "Utilizing cutting-edge language models and bespoke AI pipelines, we aim to deliver seamless and enriching user interactions.",
  },
];

const getGradient = (index) => {
  switch (index) {
    case 0:
      return "linear-gradient(to bottom, #A8DADC, #A2D5C6)";
    case 1:
      return "linear-gradient(to bottom, #A2D5C6, #9CD1BF)";
    case 2:
      return "linear-gradient(to bottom, #9CD1BF, #96CCB9)";
    default:
      return "linear-gradient(to bottom, #96CCB9, #90C8B2)";
  }
};

const App = () => {
  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault(); // Prevent the install prompt
      // You can save the event for triggering it later
      // window.deferredPrompt = e;
      return false;
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    // Cleanup the listener on unmount
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []); // Empty array ensures this effect runs once on mount only
  return (
    <div className="App background-offwhite snap-container">
      <Router>
        <div className="App background-offwhite snap-container">
          {/* ... other code */}
          <Routes>
            <Route path="/demo/*" element={<DemoContainer />} />
            <Route
              path="/"
              element={
                <>
                  <Parallax
                    strength={500}
                    style={{
                      padding: "20px",
                      textAlign: "center",
                      background:
                        "linear-gradient(to bottom, #E8F6F3, #D0F0E3)",
                    }}
                    className="snap-section"
                  >
                    <IntroSection />
                  </Parallax>
                  <Parallax
                    strength={500}
                    className="height-min-100 info-parallax snap-section"
                    style={{
                      background:
                        "linear-gradient(to bottom, #D0F0E3, #A8DADC)",
                    }}
                  >
                    <div className="grid-container">
                      {sections.map((section, index) => (
                        <InfoCard key={index} {...section} />
                      ))}
                    </div>
                  </Parallax>
                  {features.map((feature, index) => (
                    <Parallax
                      key={index}
                      strength={500}
                      className="feature-parallax snap-section"
                      style={{
                        padding: "20px",
                        textAlign: "center",
                        background: getGradient(index),
                        boxShadow: "inset 0 0 50px rgba(0, 0, 0, 0.1)", // Gradient border effect
                      }}
                    >
                      <FeatureCard {...feature} />
                    </Parallax>
                  ))}
                  <Footer />
                </>
              }
            />
          </Routes>
        </div>
      </Router>
    </div>
  );
};

export default App;
